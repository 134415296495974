import { useState } from 'react';

import UserInfo from 'components/userInfo';
import Header from 'components/header';

import person from '../../assets/person.svg';
import merchant from '../../assets/merchant.svg';

import * as S from './style';
import TerminalAPI from 'services/TerminalAPI';
import { useMutation } from 'react-query';

const symbolsArr = ['e', 'E', '+', '-'];

const PushPayment = ({ boostTag, name, isConsumer }) => {
  const [amount, setAmount] = useState<number>();
  const [notes, setNotes] = useState<string>();
  const [displayWarning, setDisplayWarning] = useState<string>('');

  const mutation = useMutation(TerminalAPI.transactionByAnonymous, {
    onSuccess: (mutationData) => {
      window.location.href = mutationData.linkPaymentUrl;
    },
    onError: (e: { details: string }) => {
      setDisplayWarning(e.details);
    },

  });

  const submit = () => {
    if(amount && amount > 0) {
      mutation.mutate({ amount: amount * 100, notes, currency: 'GBP', tag:boostTag });
    } else {
      setDisplayWarning('Invalid amount');
    }
  };

  return (
    <S.Column>
      <Header />
      <S.Container> 
      <UserInfo 
        icon={isConsumer ? person : merchant}
        title={name}
        subtitle={`£${boostTag}`}
      />
        <S.Label>Payment total</S.Label>
        <S.Input
          withAsset
          type="number"
          onChange={(e)=>{
            const fixedNumber = Number(e.target.value).toFixed(2);
            setAmount(Number(fixedNumber));
          }}
          value={amount?.toString().replace(/^0+/, '')}
          placeholder="Enter amount"
          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
        />
        <S.Label warning>{displayWarning}</S.Label>
        <S.Label>Note (optional)</S.Label>
        <S.Input
          onChange={(e)=> setNotes(e.target.value)}
          value={notes}
          placeholder="Add a note"
        />
      </S.Container>
      <S.Button onClick={submit}>Continue</S.Button>
    </S.Column>
  );
};

export default PushPayment;