import styled from 'styled-components';
import { colours } from 'constants/colours';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const Image = styled.img`
  width: 45px;
  height: 45px;
  padding: 7.5px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${colours.button};
`;

export const Title = styled.p`
  color: ${colours.charcoal};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.24px;
`;

export const Subtitle = styled.p`
  color: ${colours.charcoal};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.24px;
`;
