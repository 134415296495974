import styled from 'styled-components';

import { colours } from 'constants/colours';

// import bgImg from '../../assets/bkground.png';

export const View = styled.main`
  flex: 1;
  height: 100%;
  background-color: ${colours.white} !important;
  background: no-repeat bottom right;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  border-radius: 10px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 20px 0;
  border-bottom: 1px solid ${colours.button};
`;

export const ConfirmationText = styled.p`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.44;
  color: ${colours.greyText};
`;

export const MessageText = styled.p`
  font-size: 15px;
  font-weight: 400;
  white-space: pre-line;
  color: ${colours.greyText};
`;
