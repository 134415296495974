import { AUTH_PAYMENT_URL, markedAsDisplayed } from '../constants/urls';
import PostAPI from './PostAPI';

const AuthPaymentAPI = {
  authPayment: async (data: { bankId: string; transactionId: string }) => {
    const response = await PostAPI.post(AUTH_PAYMENT_URL, {
      fiIdentifier: data.bankId,
      transactionId: data.transactionId
    });
    return response || [];
  },
  markAsDisplayed: async (data: { transactionRef: string }) => {
    const response = await PostAPI.post(
      markedAsDisplayed(data.transactionRef),
      {}
    );
    return response || [];
  }
};

export default AuthPaymentAPI;
