import { createGlobalStyle } from 'styled-components';

import { colours } from 'constants/colours';

export const GlobalStyle = createGlobalStyle`
  html, #root {
    height: 100%;
    background-color: ${colours.offWhite};
  }

  body {
    height: 100%;
    margin: 0;
    font-family: 'Poppins', 'system-ui', '-apple-system', sans-serif;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *::-webkit-scrollbar {
    width: 8px;
  }
  
  *::-webkit-scrollbar-track {
    background: ${colours.scrollbar};
    margin: 8px 0;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${colours.navy};
  }
  
  *::-webkit-scrollbar-thumb:hover {
    opacity: 0.9;
  }
`;
