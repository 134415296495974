import styled from 'styled-components';
import { colours } from 'constants/colours';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: ${colours.white};
`;

export const Description = styled.p`
  align-self: center;
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  font-style: normal;
  font-family: 'Poppins';
  color: ${colours.black};
`;

export const ErrorContainer = styled.div`
  width: 92%;
  align-items: center;
  justify-content: center;
  margin: 24px;
  padding: 50px 50px;
  border-radius: 10px;
  background-color: ${colours.background};
  box-shadow: 6px -6px 20px rgba(0, 0, 0, 0.1);
`;
