import styled from 'styled-components';
import { colours } from 'constants/colours';

export const Wrapper = styled.div`
  width: 100%;
  padding: 17px;
`;

export const Container = styled.div<{ outline?: string }>`
  padding: 16px 18px;
  width: 100%;
  background-color: ${colours.lightBlue};
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  border-radius: 8px;
  border: ${(props) => (props.outline ? `2px solid ${props.outline}` : 'none')};
`;

export const Spacer = styled.div<{ height?: number }>`
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : '7px')};
`;

export const Label = styled.p`
  color: ${colours.darkGrey};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
`;

export const Price = styled.p`
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  color: ${colours.greyText};
`;

export const ItemValue = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: ${colours.charcoal};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
