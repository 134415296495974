import { useState, useEffect, useMemo } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import Bowser from 'bowser';
import styled from 'styled-components';

import Header from 'components/header';
import PaymentSummary from 'components/paymentSummary';
import Loader from 'components/Loader';
import BanksList from 'pages/banks/BanksList';
import TerminalAPI from 'services/TerminalAPI';
import AuthPaymentAPI from 'services/AuthPaymentAPI';
import PushPayment from 'pages/pushPayment';
import { QueryStatus } from 'constants/queryStatus';
import { colours } from 'constants/colours';
import { AccountType, ITransactionWithProfile } from 'models/models';
import { Container, Description, ErrorContainer } from './styles';

import Info from './Info';
import BrowserNotSupported from './browserNotSupported';
import { recordEvent } from 'utils/firebase';

export const NoteText = styled.p`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.44;
  color: ${colours.greyText};
`;

const MainPage = () => {
  const [continueAnyway, setContinueAnyway] = useState(false);
  const { ref } = useParams<{ ref: string }>();
  const [noTransaction, setNoTransaction] = useState(false);
  const [showPushPayment, setShowPushPayment] = useState(false);
  const history = useHistory();

  const mutation = useMutation(AuthPaymentAPI.markAsDisplayed);

  const browser = Bowser.getParser(window.navigator.userAgent);
  const supportedBrowser =
    browser.satisfies({ chrome: '>0' }) ||
    (browser.getPlatform().vendor === 'Apple' &&
      browser.getPlatform().type !== 'desktop');

  const isLink = window.location.host.includes('link');

  const [transaction, setTransaction] = useState<
  ITransactionWithProfile | undefined
  >(undefined);

  const { status: linkTransactionStatus } = useQuery(
    'linkData',
    TerminalAPI.getDetailsByTxnRef(ref),
    {
      enabled: isLink,
      onError: () => {
        setNoTransaction(true);
        setTransaction(undefined);
      },
      onSuccess: (data) => {
        setNoTransaction(false);
        setTransaction(data);
        recordEvent('Consumer-Payment_flow_start', {
          Payment_loaded: true,
          Terminal_ID: transaction?.profile.tags?.[0]?.tag || undefined,
          Merchant_ID: transaction?.profile.id
        });
        if (data) {
          mutation.mutate({ transactionRef: data.reference });
        }
      }
    }
  );

  const { data: boostTag, status: tagTransactionStatus } = useQuery(
    'tagData',
    TerminalAPI.getDetailsByBoostTag(ref.replace('£', '')),
    {
      enabled: !isLink,
      onError: () => {
        setNoTransaction(true);
        setTransaction(undefined);
      },
      onSuccess: (data) => {
        if (data) {
          if (data.currentTransaction) {
            setNoTransaction(false);
            setTransaction({
              ...data.currentTransaction,
              profile: data.profile
            });
            recordEvent('Consumer-Payment_flow_start', {
              Payment_loaded: true,
              Terminal_ID: data.profile.tags?.[0]?.tag || undefined,
              Merchant_ID: data.profile.id
            });
            mutation.mutate({
              transactionRef: data.currentTransaction.reference
            });
          } else {
            setNoTransaction(true);
            setTransaction(undefined);
            if (
              data.profile.tags?.[0]?.pushPaymentEnabled ||
              data.profile.defaultTag?.pushPaymentEnabled
            ) {
              setShowPushPayment(true);
            }
          }
        }
      }
    }
  );

  const transactionStatus = useMemo(() => {
    if (isLink) {
      return linkTransactionStatus;
    }
    return tagTransactionStatus;
  }, [isLink, linkTransactionStatus, tagTransactionStatus]);

  const details = useMemo(() => {
    if (isLink && transaction) {
      return {
        name: transaction?.profile?.name,
        accountDetails: `${transaction?.profile?.bankAccount.sortCode || ''}  ${
          transaction?.profile?.bankAccount.accountNumber || ''
        }`,
        boostTag:
          transaction?.profile?.defaultTag?.tag ||
          transaction?.profile.tags?.[0]?.tag,
        isConsumer: transaction?.profile?.type === AccountType.personal
      };
    } else if (!isLink && boostTag) {
      return {
        name: boostTag?.profile?.name,
        accountDetails: `${boostTag?.profile?.bankAccount.sortCode || ''}  ${
          boostTag?.profile?.bankAccount.accountNumber || ''
        }`,
        boostTag:
          boostTag?.profile?.defaultTag?.tag ||
          boostTag?.profile?.tags?.[0]?.tag,
        isConsumer: boostTag?.profile?.type === AccountType.personal
      };
    }
    return { name: '', accountDetails: '', boostTag: '', isConsumer: false };
  }, [boostTag, isLink, transaction]);

  useEffect(() => {
    if (transaction && transaction.status === 'DECLINED') {
      history.push(
        `status/failed?profile=${transaction?.profile.id}&transactionRef=${transaction?.reference}&amount=${transaction?.amount}&currency=${transaction?.currency}`
      );
    }
    if (transaction && transaction.status === 'APPROVED') {
      history.push(
        `status/success?profile=${transaction?.profile.id}&transactionRef=${transaction?.reference}&amount=${transaction?.amount}&currency=${transaction?.currency}`
      );
    }
  }, [history, transaction]);

  return (
    <>
      {showPushPayment ? (
        <PushPayment
          boostTag={details.boostTag}
          name={details.name}
          isConsumer={details.isConsumer}
        />
      ) : (
        <Container>
          <Header />
          {!supportedBrowser && !continueAnyway ? (
            <BrowserNotSupported close={() => setContinueAnyway(true)} />
          ) : (
            <>
              {transactionStatus !== QueryStatus.SUCCESS ? (
                <>
                  <PaymentSummary
                    name={details.name}
                    paymentRef={transaction?.reference}
                    price={`£${((transaction?.amount ?? 0) / 100).toFixed(2)}`}
                    accountDetails={details.accountDetails}
                    notes={transaction?.notes}
                    boostTag={details.boostTag}
                    isConsumer={details.isConsumer}
                    outline={colours.black}
                  />

                  {noTransaction ? (
                    <ErrorContainer>
                      <Description>{'No Transaction Found'}</Description>
                    </ErrorContainer>
                  ) : (
                    <Loader text={''} />
                  )}
                </>
              ) : (
                <>
                  <PaymentSummary
                    name={details.name}
                    paymentRef={transaction?.reference}
                    price={`£${((transaction?.amount ?? 0) / 100).toFixed(2)}`}
                    accountDetails={details.accountDetails}
                    notes={transaction?.notes}
                    boostTag={details.boostTag}
                    isConsumer={details.isConsumer}
                    outline={colours.black}
                  />
                  {/* <Info /> */}
                  {transaction ? (
                    <BanksList
                      amount={transaction?.amount}
                      transactionId={transaction?.id}
                      terminal={
                        transaction.profile.tags?.[0]?.tag || undefined
                      }
                    />
                  ) : (
                    <ErrorContainer>
                      <Description>{'No Transaction Found'}</Description>
                    </ErrorContainer>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default MainPage;
