import axios from 'axios';

const PostAPI = {
  post: async (url: string, data?: unknown) => {
    const response = await axios
      .post(url, data, {
        headers: {
          redirectURL: 'http://localhost:3000/'
        }
      })
      .catch((error) => {
        if (error.response) {
          throw error.response.data;
        }
        throw error;
      });
    return response?.data;
  }
};

export default PostAPI;
