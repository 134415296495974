import styled from 'styled-components';
import { colours } from 'constants/colours';
import { ToastContainer as ReactToastContainer } from 'react-toastify';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.p`
  color: ${colours.charcoal};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const Description = styled.p`
  color: ${colours.charcoal};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px;
  padding: 16px;
  border-radius: 10px;
  background-color: ${colours.lightBlue};
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  border: ${`2px solid ${colours.ctaBlue}`};
`;

export const ChromeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ImageWrapper = styled.div`
  width: 30px;
  margin-right: 16px;
`;

export const WhyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  margin-bottom: 8px;
  color: ${colours.darkGrey};

  cursor: pointer;
`;

export const WhyDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 16px;
  padding: 20px;
  border-radius: 10px;
  background-color: ${colours.lightBlue};
  border: ${`2px solid ${colours.ctaBlue}`};
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

export const CopyButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 12px;
  margin: 8px 16px 16px;
  background: ${colours.button};
  color: ${colours.background};

  border: none;
  border-radius: 8px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.3px;

  cursor: pointer;
`;

export const ContinueButton = styled.button`
  background: transparent;
  border: none;
  color: ${colours.darkGrey};

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  cursor: pointer;
`;

export const ToastText = styled.p`
  width: 100%;
  text-align: center;
  color: ${colours.white};
`;

export const ToastContainer = styled(ReactToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast'
})`
  .toast {
    width: 250px;
    min-height: 30px;
    height: 50px;
    margin: auto;
    background-color: ${colours.charcoal};
  }
`;
