import { useEffect, useCallback } from 'react';

import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';

import TerminalAPI from 'services/TerminalAPI';
import PaymentSummary from 'pages/PaymentSummary';

import {
  View,
  Container,
  MessageText,
  LogoContainer,
  ConfirmationText
} from './styled';
import cancelled from '../../assets/cancelled.svg';
import facebook from '../../assets/facebook.svg';
import linkedin from '../../assets/linkedin.svg';
import instagram from '../../assets/instagram.svg';
import paymii from '../../assets/paymii.svg';
import * as FirestoreService from '../../utils/firebase';
import { recordEvent } from '../../utils/firebase';
import { colours } from 'constants/colours';
import payWithBoostInfo from '../../assets/pay-with-boost.svg';

const FailurePage = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const params: { terminal: string; transactionsRef: string } = useParams();

  const terminal =
    query.has('terminal') && query.get('terminal') !== null
      ? query.get('terminal')!
      : '';
  const transactionRef =
    query.has('transactionRef') && query.get('transactionRef') !== null
      ? query.get('transactionRef')!
      : '';

  const isDeclined =
    query.has('declined') && query.get('declined') !== null ? true : false;

  const amount =
    query.has('amount') && query.get('amount') !== null
      ? parseFloat(query.get('amount')!)
      : 0;

  const errorEnum =
    query.has('error') && query.get('error') !== null
      ? query.get('error')!
      : undefined;

  const { data: transaction } = useQuery(
    'terminalData',
    TerminalAPI.getDetailsByTxnRef(transactionRef)
  );

  useEffect(() => {
    recordEvent('Consumer-Payment_error', { Error_code: errorEnum });
  }, [errorEnum]);

  const getError = useCallback(() => {
    if (errorEnum !== undefined) {
      switch (errorEnum) {
        case 'FI_DECLINED':
          return 'Bank declined transaction';
        case 'USER_DECLINED':
          return 'User declined transaction';
        case 'TRANSACTION_PROCESSING_STARTED':
          return 'Transaction processing has been started before.';
        case 'FI_CONNECTION_ERROR':
          return 'Error connecting to the FI.';
        case 'TRANSACTION_APPROVED':
          return 'Transaction already approved before.';
        default:
          return `Your bank has declined the payment.
          No money was taken from your account.
          Please try again.`;
      }
    }

    return `Your bank has declined the payment.
    No money was taken from your account.
    Please try again.`;
  }, [errorEnum]);

  return (
    <View>
      <Container>
        <LogoContainer>
          <img src={paymii} alt="icon" />
        </LogoContainer>
        <PaymentSummary
          payeeName={transaction?.profile?.name}
          merchantName={transaction?.profile?.name}
          paymntRef={params?.transactionsRef}
          price={`£${((amount ?? 0) / 100).toFixed(2)}`}
          accountDetails={`${transaction?.profile?.bankAccount.sortCode} ${transaction?.profile?.bankAccount.accountNumber}`}
        />
        <div style={{ textAlign: 'center' }}>
          <ConfirmationText>
            {isDeclined ? 'Payment declined' : 'Payment cancelled'}
          </ConfirmationText>
        </div>
        <div style={{ textAlign: 'center' }}>
          <MessageText>{getError()}</MessageText>
        </div>

        <img src={cancelled} alt="cancelled" />
      </Container>
    </View>
  );
};

export default FailurePage;
