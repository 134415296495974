import styled from 'styled-components';
import Routes from 'components/Routes';
import { colours } from 'constants/colours';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colours.white};
  height: 100%;
`;

function App(): JSX.Element {
  return (
    <Layout>
      <Routes />
    </Layout>
  );
}

export default App;
