export interface IBank {
  id: string;
  fullName: string;
  name: string;
  iconURL: string;
  logoURL: string;
}

export interface IProfile {
  country: string;
  id: number;
  tag: string;
  name: string;
  status: string;
  type: AccountType;
  tags: {
    id: number;
    tag: string;
    pushPaymentEnabled: boolean;
    requestPaymentEnabled: boolean;
  }[];
  defaultTag: {
    id: number;
    tag: string;
    pushPaymentEnabled: boolean;
    requestPaymentEnabled: boolean;
  };
  bankAccount: {
    accountNumber: string;
    bankIconUrl: string;
    bankId: number;
    bankName: string;
    sortCode: string;
    type: string;
  };
}
export interface IBoostTag {
  id: number;
  reference: string;
  status: string;
  tag: string;
  currentTransaction?: ITransaction;
  profile: IProfile;
}
export interface ITransaction {
  amount: number;
  createdAt: string;
  currency: string;
  id: number;
  reference: string;
  status: string;
  notes: string;
}

export type ITransactionWithProfile = ITransaction & {
  profile: IProfile;
};

export enum AccountType {
  business = 'BUSINESS',
  personal = 'PERSONAL'
}

export enum BanksType {
  business = 'BUSINESS',
  personal = 'PERSONAL'
}

export interface IBankAccountDetails {
  type: string;
  accountNumber: string;
  sortCode: string;
  iban: string;
}

export interface IPushPayment {
  reference: string;
  tagPaymentUrl: string;
  amount: number;
  currency: string;
  status: string;
  createdAt: string;
  id: number;
  linkPaymentUrl: string;
}
