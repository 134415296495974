import * as S from './style';

const UserInfo = ({ icon, title, subtitle }: { icon?: string; title?: string; subtitle?: string; }) => (
  <S.Row>
    <S.Image src={icon} />
    <S.Details>
      <S.Title> {title} </S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
    </S.Details>
  </S.Row>
);

export default UserInfo;