import { useState } from 'react';
import Collapsible from 'react-collapsible';

import { InfoContainer, InfoDescription, Row } from 'components/styles-shared';
import { recordEvent } from 'utils/firebase';

import info from '../../assets/info.svg';
import infoGrey from '../../assets/grey-info.svg';
import minusGrey from '../../assets/remove-circle-outline-grey.svg';
import minus from '../../assets/remove-circle-outline.svg';
import paymiLogo from '../../assets/paymiLogo.svg';
import paymii from '../../assets/paymii.svg';
const PayWithBoostInfo = () => {
  const [openMainKey, setOpenMainKey] = useState<boolean>(false);
  const [openTrust, setOpenTrust] = useState<boolean>(false);
  const [openAbout, setOpenAbout] = useState<boolean>(false);

  return (
    <InfoContainer>
      <Row>
        <img width="36" height="36" src={paymiLogo} />
        <img src={paymii} />
      </Row>
      {/* <Collapsible
      triggerClassName="parentCollapsibleTrigger"
      contentInnerClassName="parentCollapsibleInner"
      contentOuterClassName="parentCollapsibleOuter"
      triggerOpenedClassName="parentCollapsibleTrigger"
      onOpen={() => {
        setOpenMainKey(true); 
        recordEvent('Consumer-Boost_pressed', {});
      }}
      onClose={() => {
        setOpenMainKey(false);
        recordEvent('Consumer-Boost_pressed', {});
      }}
      trigger={
        <Row>
          <img width="17" height="17" src={openMainKey ? minusGrey : infoGrey} />
          <img src={boost} />
        </Row>
      }>
      <Collapsible
        key={1}
        triggerClassName="childCollapsibleTrigger"
        triggerOpenedClassName="childCollapsibleTrigger"
        onOpen={() => {setOpenAbout(true);}}
        onClose={() => {setOpenAbout(false);}}
        trigger={
          <>
            {'Who is Boost?'}
            <img width="17" height="17" src={openAbout ? minus : info} />
          </>
        }>
        <InfoDescription>
          Boost is a digital payment method and network, enabling merchants and
          banks to offer debit payment options to customers at checkout.
          <br />
          <br />
          Anyone with a UK bank account can use Boost.
        </InfoDescription>
      </Collapsible>
      <Collapsible
        key={2}
        triggerClassName="childCollapsibleTrigger"
        triggerOpenedClassName="childCollapsibleTrigger"
        onOpen={() => {setOpenTrust(true);}}
        onClose={() => {setOpenTrust(false);}}
        trigger={
          <>
            {'Can I trust Boost?'}
            <img width="17" height="17" src={openTrust ? minus : info} />
          </>
        }>
        <InfoDescription>
          All payments require your personal authorisation through your
          bank&apos;s app or website.
          <br />
          <br />
          Our systems and partners are regulated by the FCA. All payments
          require your approval to authorise through your bank&apos;s app or
          website.
          <br />
          <br />
          You can visit our{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.paywithboost.com/faqs"
            onClick={() => {
              recordEvent('Consumer-External_Link_pressed', {
                link_name: 'boost_faq'
              });
            }}>
            website
          </a>{' '}
          for more information.
          <br />
          <br />
          By continuing to your bank, you are agreeing to our{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.paywithboost.com/customerterms"
            onClick={() => {
              recordEvent('Consumer-External_Link_pressed', {
                link_name: 'customer_terms'
              });
            }}>
            Customer Terms
          </a>{' '}
          &amp;{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.paywithboost.com/privacy"
            onClick={() => {
              recordEvent('Consumer-External_Link_pressed', {
                link_name: 'boost_privacy'
              });
            }}>
            Privacy Policy.
          </a>
        </InfoDescription>
      </Collapsible>
    </Collapsible> */}
    </InfoContainer>
  );
};

export default PayWithBoostInfo;
