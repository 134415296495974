import styled from 'styled-components';

import { colours } from 'constants/colours';

export const InfoContainer = styled.div`
  width: 100%;
  .parentCollapsibleOuter {
    margin-top: 10px;
  }

  .parentCollapsibleInner {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 7px 17px 17px;
    padding: 10px 22px 22px;
    background-color: ${colours.background};
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .parentCollapsibleTrigger {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
  }

  .childCollapsibleTrigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: ${colours.charcoal};
  }
`;

export const InfoDescription = styled.p`
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  font-style: normal;
  white-space: pre-wrap;
  font-family: 'Poppins';
  color: ${colours.greyText};

  a,
  a:visited {
    color: ${colours.charcoal};
    cursor: 'pointer';
  }
`;

export const InfoRegulations = styled.span`
  margin-top: 7px;
  font-size: 10px;
  text-align: left;
  font-style: normal;
  font-family: 'Poppins';
  color: ${colours.charcoal};

  a,
  a:visited {
    color: ${colours.charcoal};
    cursor: 'pointer';
  }
`;

export const Strong = styled.span`
  font-weight: 500;
`;

export const YapilyText = styled.span`
  color: ${colours.black};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 17px;
`;
