import styled from 'styled-components';
import { colours } from 'constants/colours';

export const ImageContainer = styled.div`
  width: 100%;
  padding-top: 17px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${colours.button};
  margin: 0px 17px;
`;
