import axios from 'axios';

const FetchAPI = {
  get: async (url: string) => {
    const response = await axios.get(url, {
      headers: {
        user_type: 'PAYER',
        'Access-Control-Allow-Origin': '*',
        redirectURL: 'http://localhost:3000/'
      }
    });
    return response?.data;
  }
};

export default FetchAPI;
