import { useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { useParams } from 'react-router-dom';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { recordEvent } from 'utils/firebase';

import chrome from '../../../assets/chrome.svg';
import info from '../../../assets/grey-info.svg';
import copy from '../../../assets/copy.svg';
import * as S from './style';

const BrowserNotSupported = ({ close }: { close: () => void }) => {
  const { ref } = useParams<{ ref: string }>();

  const copyLink = () => {
    recordEvent('payer-Payment-Chrome_advice_link_copied', {
      payment_reference: ref
    });
    const url = window.location.href;
    window.navigator.clipboard.writeText(url);
    toast(<S.ToastText>Link is copied</S.ToastText>, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
  };

  const continueAnyway = () => {
    recordEvent('payer-Payment-Chrome_advice_proceed', {
      payment_reference: ref
    });
    close();
  };

  useEffect(() => {
    recordEvent('payer-Payment-Chrome_advice', {
      payment_reference: ref
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Column>
      <S.Container>
        <S.ImageWrapper>
          <img width="30" src={chrome} />
        </S.ImageWrapper>
        <S.ChromeContent>
          <S.Header>Paying with paymii works best on Chrome.</S.Header>
          <S.Description>
            Tap below to copy the link then open Chrome and paste the link to
            continue the payment experience.
          </S.Description>
        </S.ChromeContent>
      </S.Container>
      <Collapsible
        transitionTime={0.1}
        triggerClassName="parentCollapsibleTrigger"
        contentInnerClassName="parentCollapsibleInner"
        contentOuterClassName="parentCollapsibleOuter"
        triggerOpenedClassName="parentCollapsibleTrigger"
        trigger={
          <S.WhyContainer>
            why? <img width="15" object-fit="contain" src={info} />
          </S.WhyContainer>
        }>
        <S.WhyDescription>
          To pay with Paymii, we redirect you to your bank app to approve the
          payment. Your current browser doesn’t support redirection to bank
          apps, but Chrome supports redirection to bank apps by default which
          will facilitate a seamless payment experience for you.
        </S.WhyDescription>
      </Collapsible>
      <S.CopyButton onClick={copyLink}>
        <img width="15" object-fit="contain" src={copy} />
        Copy link
      </S.CopyButton>
      <S.ContinueButton onClick={continueAnyway}>
        Continue anyway
      </S.ContinueButton>
      <S.ToastContainer closeButton={false} transition={Slide} limit={1} />
    </S.Column>
  );
};

export default BrowserNotSupported;
