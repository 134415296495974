import {
  query,
  onSnapshot,
  collection,
  getFirestore
} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { logEvent, getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  appId: process.env.REACT_APP_APP_ID,
  apiKey: process.env.REACT_APP_API_KEY,
  projectId: process.env.REACT_APP_PROJECT_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics(app);
analytics.app.automaticDataCollectionEnabled = true;

export const recordEvent = (eventName, props) => {
  logEvent(analytics, eventName, props);
};

export const authenticateAnonymously = () => signInAnonymously(getAuth(app));

export const streamStates = (profile, transaction, snapshot, error) => {
  const itemsColRef = collection(
    db,
    'profile',
    profile,
    'transactions',
    transaction,
    'status'
  );
  const itemsQuery = query(itemsColRef);
  return onSnapshot(itemsQuery, snapshot, error);
};
