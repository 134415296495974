import UserInfo from 'components/userInfo';
import person from '../../assets/person.svg';
import merchant from '../../assets/merchant.svg';
import * as S from './styles';
import Loader from 'components/Loader';

const Item = ({ label, value }) => (
  <S.Column>
    <S.Label>{label}</S.Label>
    <S.ItemValue>{value}</S.ItemValue>
  </S.Column>
);

const PaymentSummary = ({
  date,
  price,
  name,
  isConsumer,
  boostTag,
  accountDetails,
  paymentRef,
  notes,
  outline,
  spacer,
  isLoading
}: {
  date?: string;
  price: string;
  name?: string;
  isConsumer: boolean;
  boostTag?: string;
  accountDetails?: string;
  paymentRef?: string;
  notes?: string;
  outline?: string;
  spacer?: number;
  isLoading?: boolean;
}) => (
  <S.Wrapper>
    <S.Container outline={outline}>
      {spacer && <S.Spacer height={spacer} />}
      {isLoading ? (
        <Loader text={''} />
      ) : (
        <>
          <UserInfo
            icon={isConsumer ? person : merchant}
            title={name || ''}
            subtitle={boostTag && `£${boostTag}`}
          />
          <S.Spacer />
          <S.Label>Payment total</S.Label>
          <S.Spacer />
          <S.Price>{price}</S.Price>
          {date && (
            <>
              <S.Spacer />
              <Item label="Date" value={date} />
            </>
          )}
          <S.Spacer />
          <S.Row>
            <Item label="Reference" value={paymentRef} />
            <Item label="Account details" value={accountDetails} />
          </S.Row>
          <S.Spacer />
          {notes && <Item label="Note" value={notes} />}
        </>
      )}
    </S.Container>
  </S.Wrapper>
);

export default PaymentSummary;
