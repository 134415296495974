import styled from 'styled-components';
import { colours } from 'constants/colours';

interface IList {
  isLoaded: boolean;
}
interface IParagraph {
  clickable?: boolean;
}

export const Container = styled.div<IList>`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border-radius: 10px;
  width: 100%;
  background-color: ${(props) => props.isLoaded && colours.background};
  /* box-shadow: ${(props) =>
    props.isLoaded && '6px -6px 20px rgba(0, 0, 0, 0.1)'}; */
`;

export const List = styled.ul<IList>`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: ${(props) => !props.isLoaded && 'center'};
  list-style: none;
`;

export const TopSeparator = styled.div`
  width: 39px;
  height: 3px;
  margin: 0 auto;
  border-radius: 5px;
  background-color: ${colours.blueSeparator};
`;

export const Paragraph = styled.span<IParagraph>`
  margin: 10px auto 0 auto;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  cursor: ${(props) => props.clickable && 'pointer'};
`;

export const Link = styled.a<IParagraph>`
  margin: 10px auto 0 auto;
  color: ${colours.charcoal};
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  cursor: ${(props) => props.clickable && 'pointer'};
`;

export const ErrorSpan = styled.span<IParagraph>`
  align-self: center;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 35px;
  /* padding: 5px; */
  border-width: 1px;
  border: ${`2px solid ${colours.charcoal}`};
  margin: 20px auto 0px;
  gap: 5px;
  background-color: ${'transparent'};
  border-radius: 20px;
`;

export const Tab = styled.button<{ selected?: boolean; right?: true }>`
  width: 180px;
  height: 31px;

  color: ${(props) => (props.selected ? colours.white : colours.charcoal)};
  background-color: ${(props) =>
    props.selected ? colours.secondary : 'transparent'};
  background-position: ${(props) => (props.right ? 'right' : 'left')} bottom;
  border: 0px;
  border-radius: 20px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  text-align: center;
  letter-spacing: 0.75px;

  transition: all 0.2s ease-out;
`;
