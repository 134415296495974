import styled from 'styled-components';

import { colours } from 'constants/colours';

import bgImg from '../../assets/bkground.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: no-repeat bottom right;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.2);
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 20px 0;
  border-bottom: 1px solid ${colours.button};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 400px;

  .boldText {
    font-weight: 700;
    color: ${colours.greyText};
  }

  @media (max-width: 480px) {
    max-width: 90%;
  }
`;

export const TextContainer = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${colours.greyText};
`;

export const SocialsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const BottomText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 30px;
`;

export const TextBold = styled.p`
  color: ${colours.charcoal};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export const Description = styled.p`
  margin: 7px 17px 15px;
  color: ${colours.charcoal};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const Spacer = styled.div`
  margin-top: 60px;
`;

export const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  width: 272px;
  height: 42px;
  min-height: 42px;
  margin-top: 17px;

  color: ${colours.white};
  background-color: ${colours.blueSeparator};
  border-radius: 25px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;

  z-index: 1;
`;

export const SuccessText = styled.div`
  margin-left: -5px;
`;

export const PaymentSummaryWrapper = styled.div`
  width: 100%;
  margin-top: -40px;
`;

export const AnimationWrapper = styled.div`
  margin-left: -7px;
  width: 60px;
  height: 60px;
`;
