import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import MainPage from 'pages/main';
import SuccessPage from 'pages/Success';
import Failure from 'pages/Failure';
import { colours } from 'constants/colours';
import PushPayment from 'pages/pushPayment';

const Container = styled.main`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 850px;
  height: 100%;
  background-color: ${colours.white};
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.2);
`;

const Routes = () => (
  <Container>
    {/*
        A <Switch> looks through all its children <Route> elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time you have multiple routes, but you want only one
        of them to render at a time. Once it finds the url, the route is rendered while the rest are ignored. 
      */}
    <Switch>
      {/* 
          'exact' is used here because '<Route path>' will always match the beginning of the URL, not the whole thing.
          So a <Route path="/"> will always match the URL, no matter what it is. Because of this, we typically put this <Route> last in our <Switch>. 
          Another possible solution is to use <Route exact path="/"> which DOES match the entire URL.
        */}
      <Route exact path="/status/success" component={SuccessPage} />
      <Route exact path="/push-payment" component={PushPayment} />
      <Route exact path="/status/failed" component={Failure} />
      <Route exact path="/terminal/:ref" component={MainPage} />
      <Route exact path="/:ref" component={MainPage} />
      {/* <Route path="*" component={SuccessPage} /> */}
      {/* <Route exact path="/about" component={AboutPage} /> */}
    </Switch>
  </Container>
);

export default Routes;
