import { IBank } from 'models/models';
import { getBanks } from '../constants/urls';
import FetchAPI from './FetchAPI';

const BanksAPI = {
  getBanks: async (type: string) => {
    const response = await FetchAPI.get(getBanks(type));
    const data: IBank[] = response;
    return data || [];
  }
};

export default BanksAPI;
